/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useThemeContext } from 'hook/global-theme';
import {
  ScrollDomContainer,
  ScrollDomContent,
} from 'components/page/goods-detail/goods-layout/hooks/useScrollContainer';
import DesignLayout from 'components/page/design-layout';
import { getPageDecorationData } from 'helper/goods';
import { buriedPoint } from 'utils/log-buried-point';
import get from 'lodash/get';
import sectionConfig from './config';
import cn from 'classnames';
import { Style } from './style';

const GoodsDetail = (propsData) => {
  const props = propsData;

  // props.initialData.goods = data.data;

  const { isMobile } = useThemeContext(); // 是否是手机端
  const [baseConfig, setBaseConfig] = useState();
  const isScroll = !isMobile && (baseConfig?.style === 'style3' || baseConfig?.style === 'style2');

  const getBaseConfig = (themeData) => {
    const sectionSetting = themeData?.current?.sections?.['product-template'] ?? {};
    const { baseConfig = {} } = getPageDecorationData(sectionSetting, isMobile); // 设置页面装修数据
    setBaseConfig(baseConfig);
  };

  useEffect(() => {
    getBaseConfig(props?.initialData?.themeData);
  }, []);

  useEffect(() => {
    const goodsDetail = get(props, 'initialData.goods', {});
    buriedPoint('view_item', { source: { goodsDetail } });
    const collectViewContent = () => {
      buriedPoint('view_content', { ...goodsDetail });
      // 需要在页面内停留几秒，才算“浏览内容”,否则只上报pageView。这就是定时器存在的原因
    };

    window.addEventListener('load', collectViewContent);
    return () => window.removeEventListener('load', collectViewContent);
  }, []);

  const onThemeChange = (newTheme) => {
    getBaseConfig(newTheme);
  };

  return (
    <Style
      className={cn(ScrollDomContainer, {
        'is-scroll-animation': isScroll,
      })}
    >
      <div className={cn(ScrollDomContent)}>
        <DesignLayout {...props} {...sectionConfig} onThemeChange={onThemeChange} />
      </div>
    </Style>
  );
};

export default GoodsDetail;
